/* Thumb Info */
.thumb-info {
	display: block;
	position: relative;
	text-decoration: none;
	max-width: 100%;
	background-color: #FFF;
	border: 1px solid #DDD;
	border-radius: 4px;
	overflow: hidden;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);

	.thumb-info-wrapper {
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
		border-radius: 4px;
		margin: 4px;
		overflow: hidden;
		display: block;
		position: relative;

		&:after {
			content: "";
			background: rgba(23, 23, 23, 0.8);
			transition: all 0.3s;
			border-radius: 4px;
			bottom: -4px;
			color: #FFF;
			#{$left}: -4px;
			position: absolute;
			#{$right}: -4px;
			top: -4px;
			display: block;
			opacity: 0;
			z-index: 1;
		}
	}

	.thumb-info-action-icon {
		transition: all 0.3s;
		background: #CCC;
		border-radius: corner-values(0 0 0 25px);
		display: inline-block;
		font-size: 25px;
		height: 50px;
		line-height: 50px;
		position: absolute;
		#{$right}: -100px;
		text-align: center;
		top: -100px;
		width: 50px;
		z-index: 2;
		i {
			font-size: 24px;
			#{$left}: 3px;
			position: relative;
			top: -4px;
			color: #FFF;
		}
	}

	.thumb-info-inner {
		transition: all 0.3s;
		display: block;
		em {
			display: block;
			font-size: 0.8em;
			font-style: normal;
			font-weight: normal;
		}
	}

	.thumb-info-title {
		transition: all 0.3s;
		background: rgba(23, 23, 23, 0.8);
		bottom: 10%;
		color: #FFF;
		font-size: 18px;
		font-weight: 700;
		#{$left}: 0;
		letter-spacing: -1px;
		padding: 9px 11px 9px;
		position: absolute;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
		text-transform: uppercase;
		z-index: 2;
		max-width: 90%;
	}

	.thumb-info-type {
		background-color: #CCC;
		border-radius: 2px;
		display: inline-block;
		float: $left;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0;
		margin: 8px -2px -15px -2px;
		padding: 2px 9px;
		text-transform: none;
		z-index: 2;
	}

	.owl-carousel {
		z-index: auto;
		.owl-dots {
			z-index: 2;
		}
	}

	img {
		transition: all 0.3s ease;
		border-radius: 3px;
		position: relative;
		width: 100%;
	}

	&:hover {
		.thumb-info-wrapper {
			&:after {
				opacity: 1;
			}
		}
		.thumb-info-action-icon {
			#{$right}: 0;
			top: 0;
		}
		.thumb-info-title {
			background: #000;
		}
		img {
			transform: scale(1.1, 1.1);
		}
	}

	&.thumb-info-no-zoom {
		img {
			transition: none;
		}
		&:hover img {
			transform: scale(1, 1);
		}
	}

	&.thumb-info-lighten {
		.thumb-info-wrapper {
			&:after {
				background: rgba(23, 23, 23, 0.25);
			}
		}
	}

	&.thumb-info-hide-wrapper-bg {
		.thumb-info-wrapper {
			&:after {
				display: none;
			}
		}
	}

	&.thumb-info-centered-icons {
		.thumb-info-action {
			transition: transform .2s ease-out, opacity .2s ease-out;
			transform: translate3d(0,-10px,0);
			top: 50%;
			left: 3px;
			right: auto;
			bottom: auto;
			width: 100%;
			height: 40px;
			margin-top: -20px;
			text-align: center;
			position: absolute;
			opacity: 0;
			z-index: 2;
		}
		.thumb-info-action-icon {
			transition: all 0.1s ease;
			position: relative;
			z-index: 2;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
			width: 40px;
			height: 40px;
			line-height: 33px;
			border-radius: 50%;
			margin-right: 3px;
			i {
				position: static;
				font-size: 18px;
			}
			&:hover {
				transform: scale(1.15, 1.15);
			}
		}
	}

	&:hover {
		&.thumb-info-centered-icons {
			.thumb-info-action {
				transform: translate3d(0,0,0);
				opacity: 1;
			}
			.thumb-info-action-icon {
				position: relative;
			}
		}
	}

	&.thumb-info-centered-info {
		.thumb-info-title {
			background: transparent;
			left: 5%;
			width: 90%;
			transition: transform .2s ease-out, opacity .2s ease-out;
			transform: translate(0%, -30%);
			top: 50%;
			bottom: auto;
			opacity: 0;
			text-align: center;
			padding: 0;
			position: absolute;
		}
		.thumb-info-type {
			float: none;
		}
		&:hover {
			.thumb-info-title {
				opacity: 1;
				transform: translate(0%, -50%);
			}
		}
	}

	&.thumb-info-bottom-info {
		.thumb-info-title {
			background: #FFF;
			left: 0;
			width: 100%;
			max-width: 100%;
			transition: transform .2s ease-out, opacity .2s ease-out;
			transform: translate3d(0,100px,0);
			top: auto;
			bottom: 0;
			opacity: 0;
			padding: 15px;
			text-shadow: none;
			color: $color-font-default;
		}
		.thumb-info-type {
			float: none;
			background: none;
			padding: 0;
			margin: 0;
		}
		&:hover {
			.thumb-info-title {
				transform: translate3d(0,0,0);
				opacity: 1;
			}
			img {
				transform: translateY(-18px);
			}
		}
	}

	&.thumb-info-bottom-info-dark {
		.thumb-info-title {
			background: linear-gradient(to top, rgba(23, 23, 23, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
			color: #FFF;
		}
	}

	&.thumb-info-push-hor {
		img {
			transform: translatex(-18px);
			width: calc(100% + 19px);
			max-width: none;
		}
		&:hover img {
			transform: translatex(0);
		}
	}

	&.thumb-info-hide-info-hover {
		.thumb-info-wrapper {
			&:after {
				opacity: 0.65;
			}
		}
		.thumb-info-title {
			opacity: 1;
			top: 50%;
		}
		.thumb-info-type {
			float: none;
		}
		&:hover {
			.thumb-info-wrapper {
				&:after {
					opacity: 0.1;
				}
			}
			.thumb-info-title {
				opacity: 0;
			}
		}
	}

	&.thumb-info-no-borders {
		&, img {
			border: 0;
			padding: 0;
			margin: 0;
			border-radius: 0;
		}
		.thumb-info-wrapper {
			border: 0;
			padding: 0;
			margin: 0;
			border-radius: 0;
			&:after {
				border-radius: 0;
				bottom: 0;
				#{$left}: 0;
				#{$right}: 0;
				top: 0;
			}
		}
	}

	&.thumb-info-preview {
		.thumb-info-wrapper {
			&:after {
				display: none;
			}
		}
		.thumb-info-image {
			min-height: 232px;
			display: block;
			background-position: center top;
			background-repeat: no-repeat;
			background-size: 100% auto;
			position: relative;
			transition: background-position 0.8s linear 0s;
		}
		&:hover {
			.thumb-info-image {
				transition: background-position 2.5s linear 0s;
				background-position: center bottom;
			}
		}
		&.thumb-info-preview-short {
			&:hover {
				.thumb-info-image {
					transition: background-position 1s linear 0s;
				}
			}
		}
		&.thumb-info-preview-long {
			&:hover {
				.thumb-info-image {
					transition: background-position 5s linear 0s;
				}
			}
		}
	}
}

html.webkit {
	.thumb-info {
		.thumb-info-wrapper {
			margin: 4px 4px 3px 4px;
		}
	}

	.full-width .thumb-info,
	.masonry-item .thumb-info,
	.thumb-info-no-borders {
		.thumb-info-wrapper {
			margin: 0;
		}
	}
}

/* Thumb Info Ribbon */
.thumb-info-ribbon {
	background: #999;
	position: absolute;
	margin: side-values(-16px 0 0 0);
	padding: side-values(5px 13px 6px);
	#{$right}: 15px;
	z-index: 1;
	&:before {
		border-#{$right}: 10px solid #646464;
		border-top: 16px solid transparent;
		content: "";
		display: block;
		height: 0;
		#{$left}: -10px;
		position: absolute;
		top: 0;
		width: 7px;
	}
	span {
		color: #FFF;
		font-size: 1.1em;
		font-family: $font-secondary;
	}
}

/* Thumb Info - Full Width */
.full-width .thumb-info {
	img {
		border-radius: 0 !important;
	}
}

/* Thumb Info Caption  */
.thumb-info-caption {
	padding: 10px 0;
	.thumb-info-caption-text, p {
		font-size: 0.9em;
		line-height: 20px;
		margin: 0 0 8px;
		padding: 10px;
		display: block;
	}
}

/* Thumb Info Side Image  */
.thumb-info-side-image {
	.thumb-info-side-image-wrapper {
		padding: 4px;
		float: $left;
		margin-#{$right}: 20px;
	}
	.thumb-info-caption {
		.thumb-info-caption-text {
			padding: 17px;
		}
	}
}

.thumb-info-side-image-right {
	.thumb-info-side-image-wrapper {
		float: $right;
		margin-#{$left}: 20px;
		margin-#{$right}: 0;
	}
}

/* Thumb Info Social Icons */
.thumb-info-social-icons {
	border-top: 1px dotted #DDD;
	margin: 0;
	padding: 15px 10px;
	display: block;
	a {
		background: #CCC;
		border-radius: 25px;
		display: inline-block;
		height: 30px;
		line-height: 30px;
		text-align: center;
		width: 30px;
		&:hover {
			text-decoration: none;
		}
		span {
			display: none;
		}
		i {
			color: #FFF;
			font-size: 0.9em;
			font-weight: normal;
		}
	}
}

/* Responsive */
@media (max-width: 991px) {

	.thumb-info {
		.thumb-info-title {
			font-size: 14px;
		}
		.thumb-info-more {
			font-size: 11px;
		}
	}	

}