/* Thumbnails */
.rounded {
	border-radius: 4px;
}

.img-thumbnail {
	border-radius: 4px;
	position: relative;
	img {
		border-radius: 4px;
	}
	.zoom {
		background: #CCC;
		border-radius: 100%;
		bottom: 8px;
		color: #FFF;
		display: block;
		height: 30px;
		padding: 6px;
		position: absolute;
		#{$right}: 8px;
		text-align: center;
		width: 30px;
		i {
			font-size: 14px;
			#{$left}: -1px;
			position: relative;
			top: -3px;
		}
	}

	&.img-thumbnail-hover-icon {
		position: relative;
		img {
			position: relative;
			z-index: 1;
		}
		&:before {
			transition: all 0.2s;
			background: rgba(0, 0, 0, 0.65);
			border-radius: 4px;
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			z-index: 2;
		}
		&:after {
			transition: all 0.2s;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			color: #FFF;
			content: "\f00e";
			font-size: 30px;
			height: 30px;
			position: absolute;
			top: 30%;
			margin-top: -15px;
			display: block;
			text-align: center;
			width: 100%;
			opacity: 0;
			z-index: 3;
		}
		&:hover {
			&:before {
				opacity: 1;
			}
			&:after {
				top: 50%;
				opacity: 1;
			}
		}
	}
}

/* Image Gallery */
ul {
	&.image-gallery {
		list-style: none;
		margin: 0;
		padding: 0;
		.image-gallery-item {
			margin-bottom: 35px;
		}
	}
}

/* Thumbnail Gallery */
ul.thumbnail-gallery {
	list-style: none;
	margin: 10px 0;
	padding: 0;
	li {
		display: inline-block;
		margin: side-values(10px 10px 0 0);
		padding: 0;
	}
}

/* Responsive */
@media (max-width: 991px) {

	.thumbnail {
		border: 0;
		text-align: center;
	}

}

/* Masonry */
.masonry {
	.masonry-item {
		width: 25%;
		&.w2 {
			width: 50%;
		}
		img {
			border-radius: 0 !important;
		}
	}
}

/* Masonry Loading */
.masonry-loader {
	height: auto;
	overflow: visible;
	position: relative;
	&:after {
		transition: all 0.5s;
		content: ' ';
		background: #FFF;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		opacity: 0
	}
	.bounce-loader {
		display: none;
	}
	&.masonry-loader-showing {
		height: 200px;
		overflow: hidden;
		&:after {
			opacity: 1;
		}
		.bounce-loader {
			display: block;
		}
	}
	&.masonry-loader-loaded {
		&:after {
			display: none;
		}
	}
}

/* Mockup */
@media (min-width: 992px) {
	.mockup-landing-page {
		margin-top: -120px;
	}
}