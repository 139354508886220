/* Maps */
.google-map {
	background: #E5E3DF;
	height: 400px;
	margin: -35px 0 30px 0;
	width: 100%;
	&.small {
		height: 250px;
		border-radius: 6px;
		margin: 0;
		clear: both;
	}
	img {
		max-width: 9999px;
	}
}

.google-map-borders {
	border: 1px solid #DDD;
	padding: 4px;
	border-radius: 4px;
}

/* Google Map */
.google-map-footer {
	margin: 35px 0 -50px !important;
}