.loading-overlay-showing {
	overflow: hidden;

	& > .loading-overlay {
		opacity: 1;
		visibility: visible;
	}
}

.loading-overlay {
	transition: visibility 0s ease-in-out 0.5s, opacity 0.5s ease-in-out;
	bottom: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	right: 0;
	top: 0;
	visibility: hidden;
	background: #FFF;
}

body > .loading-overlay {
	position: fixed;
	z-index: 999999;
}

/* Bounce Loading */
.bounce-loader {
	transition: all 0.2s;
	margin: -9px 0 0 -35px;
	text-align: center;
	width: 70px;
	left: 50%;
	position: absolute;
	top: 50%;
	z-index: 10000;

	.bounce1,
	.bounce2,
	.bounce3 {
	    animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
	    background-color: #CCC;
	    border-radius: 100%;
	    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
	    display: inline-block;
	    height: 18px;
	    width: 18px;
	}

	.bounce1 {
		animation-delay: -0.32s;
	}

	.bounce2 {
		animation-delay: -0.16s;
	}
}

@keyframes bouncedelay {
	0%, 80%, 100% {transform: scale(0.0);} 
	40% {transform: scale(1.0);}
}

// Mobile Safari Animation Fix
html {
	&.safari.iphone {
		.bounce-loader {
			.bounce1,
			.bounce2,
			.bounce3 {
				animation: 1.4s ease-in-out infinite bouncedelay;
			}
		}
	}
}