/* Tables */
@each $state in $states {
	.table > thead > tr > td.#{nth($state,1)},
	.table > tbody > tr > td.#{nth($state,1)},
	.table > tfoot > tr > td.#{nth($state,1)},
	.table > thead > tr > th.#{nth($state,1)},
	.table > tbody > tr > th.#{nth($state,1)},
	.table > tfoot > tr > th.#{nth($state,1)},
	.table > thead > tr.#{nth($state,1)} > td,
	.table > tbody > tr.#{nth($state,1)} > td,
	.table > tfoot > tr.#{nth($state,1)} > td,
	.table > thead > tr.#{nth($state,1)} > th,
	.table > tbody > tr.#{nth($state,1)} > th,
	.table > tfoot > tr.#{nth($state,1)} > th {
		background-color: #{nth($state,2)};
		color: #{nth($state,3)};
	}
}