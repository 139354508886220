/* Toggles */
.toggle {
	margin: 10px 0 $spacement-lg;
	position: relative;
	clear: both;
	.toggle {
		margin: 10px 0 0;
	}
	> {
		input {
			cursor: pointer;
			filter: alpha(opacity = 0);
			height: 45px;
			margin: 0;
			opacity: 0;
			position: absolute;
			width: 100%;
			z-index: 2;
		}
		label {
			transition: all .15s ease-out;
			background: #F4F4F4;
			border-#{$left}: 3px solid #CCC;
			border-radius: 5px;
			color: #CCC;
			display: block;
			font-size: 1.1em;
			min-height: 20px;
			padding: side-values(12px 20px 12px 10px);
			position: relative;
			cursor: pointer;
			font-weight: 400;
			&:-moz-selection {
				background: none;
			}
			i {
				&.fa-minus {
					display: none;
				}
				&.fa-plus {
					display: inline;
				}
			}
			&:selection {
				background: none;
			}
			&:before {
				border: 6px solid transparent;
				border-#{$left}-color: inherit;
				content: '';
				margin-top: -6px;
				position: absolute;
				#{$right}: 4px;
				top: 50%;
			}
			&:hover {
				background: #f5f5f5;
			}
			+ p {
				color: #999;
				display: block;
				overflow: hidden;
				padding-#{$left}: 30px;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 25px;
			}
			i {
				font-size: 0.7em;
				margin-#{$right}: 8px;
				position: relative;
				top: -1px;
			}
		}
		.toggle-content {
			display: none;
			> p {
				margin-bottom: 0;
				padding: 10px 0;
			}
		}
	}
	&.active {
		i {
			&.fa-minus {
				display: inline;
			}
			&.fa-plus {
				display: none;
			}
		}
		> label {
			background: #F4F4F4;
			border-color: #CCC;
			&:before {
				border: 6px solid transparent;
				border-top-color: #FFF;
				margin-top: -3px;
				#{$right}: 10px;
			}
		}
		> p {
			white-space: normal;
		}
	}
	> p.preview-active {
		height: auto;
		white-space: normal;
	}

	&.toggle-sm {
		.toggle {
			> label {
				font-size: 0.9em;
				padding: 6px 20px 6px 10px;
			}
			> .toggle-content > p {
				font-size: 0.9em;
			}
		}
	}

	&.toggle-lg {
		.toggle {
			> label {
				font-size: 1.1em;
				padding: 16px 20px 16px 20px;
			}
			> .toggle-content > p {
				font-size: 1.1em;
			}
		}
	}

	&.toggle-simple {
		.toggle {
			> label {
				border-radius: 0;
				border: 0;
				background: transparent;
				color: $color-font-default;
				padding-#{$left}: 32px;
				margin-bottom: -10px;
				font-size: 1em;
				font-weight: 600;
				color: $color-dark;
				&:after {
					content: '';
					position: absolute;
					#{$left}: 0;
					display: block;
					width: 24px;
					height: 24px;
					background: #CCC;
					top: 23px;
					margin-top: -12px;
					border-radius: 3px;
					z-index: 1;
				}
				&:before {
					border: 0;
					#{$left}: 7px;
					#{$right}: auto;
					margin-top: 0;
					top: 12px;
					font-family: 'Font Awesome 5 Free';
					font-weight: 900;
					content: "\f067";
					z-index: 2;
					color: #FFF;
					font-size: 13px;
				}
			}
			&.active > label {
				background: transparent;
				color: $color-dark;
				&:before {
					content: "\f068";
				}
			}
		}
	}
}