/* Testimonials */
.testimonial {
	margin-bottom: $spacement-lg;
	blockquote {
		background: #CCC;
		border-radius: 10px;
		border: 0;
		color: #666;
		font-family: Georgia, serif;
		font-style: italic;
		margin: 0;
		padding: 10px 50px;
		position: relative;
		&:before {
			#{$left}: 10px;
			top: 0;
			color: #FFF;
			content: if-ltr("\201C", "\201D");
			font-size: 80px;
			font-style: normal;
			line-height: 1;
			position: absolute;
		}
		&:after {
			color: #FFF;
			content: if-ltr("\201D", "\201C");
			font-size: 80px;
			font-style: normal;
			line-height: 1;
			position: absolute;
			bottom: -0.5em;
			#{$right}: 10px;
		}
		p {
			color: #FFF;
			font-family: Georgia, serif;
			font-style: italic;
			font-size: 1.2em;
			line-height: 1.3;
		}
	}

	.testimonial-arrow-down {
		border-#{$left}: 15px solid transparent;
		border-#{$right}: 15px solid transparent;
		border-top: 15px solid #CCC;
		height: 0;
		margin: side-values(0 0 0 17px);
		width: 0;
	}

	.testimonial-author {
		@include clearfix;
		margin: 8px 0 0 0;
		.testimonial-author-thumbnail {
			float: $left;
			margin-#{$right}: 10px;
			width: auto;
		}
		img {
			max-width: 55px;
		}
		strong {
			color: #111;
			display: block;
			padding-top: 10px;
			margin-bottom: -5px;
		}
		span {
			color: #666;
			display: block;
			font-size: 12px;
		}
		p {
			color: #999;
			margin: side-values(0 0 0 25px);
			text-align: $left;
		}
	}
}

/* Style 2 */
.testimonial {
	&.testimonial-style-2 {
		text-align: center;
		blockquote {
			background: transparent !important;
			border-radius: 4px;
			padding: 6px 20px;
			&:before, &:after {
				display: none;
			}
			p {
				color: $color-font-default;
			}
		}
		.testimonial-arrow-down {
			display: none;
		}
		.testimonial-author {
			padding: 0;
			margin: 6px 0 0;
			img {
				margin: 0 auto 10px;
			}
			p {
				text-align: center;
				padding: 0;
				margin: 0;
			}
		}
	}
}

/* Style 3 */
.testimonial {
	&.testimonial-style-3 {
		blockquote {
			background: $color-default !important;
			border-radius: 4px;
			padding: 12px 30px;
			&:before {
				top: 5px;
				color: $color-font-default;
				font-size: 35px;
			}
			&:after {
				color: $color-font-default;
				font-size: 35px;
				bottom: -5px;
			}
			p {
				color: $color-font-default;
				font-family: $font-primary;
				font-size: 1em;
				font-style: normal;
			}
		}
		.testimonial-author {
			margin-#{$left}: 8px;
		}
		.testimonial-arrow-down {
			margin-#{$left}: 20px;
			border-top: 10px solid $color-default !important;
		}
	}
}

/* Style 4 */
.testimonial {
	&.testimonial-style-4 {
		border-radius: 6px;
		padding: 8px;
		border-top: 1px solid #DFDFDF;
		border-bottom: 1px solid #DFDFDF;
		border-#{$left}: 1px solid #ECECEC;
		border-#{$right}: 1px solid #ECECEC;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
		blockquote {
			background: transparent !important;
			border-radius: 4px;
			padding: 12px 30px;
			&:before {
				top: 5px;
				color: $color-font-default;
				font-size: 35px;
			}
			&:after {
				color: $color-font-default;
				font-size: 35px;
				bottom: -5px;
			}
			p {
				color: $color-font-default;
				font-family: $font-primary;
				font-size: 1em;
				font-style: normal;
			}
		}
		.testimonial-arrow-down {
			display: none;
		}
		.testimonial-author {
			margin-#{$left}: 30px;
			margin-bottom: 15px;
		}
		&.testimonial-no-borders {
			border: 0;
			box-shadow: none;
		}
	}
}

/* Style 5 */
.testimonial {
	&.testimonial-style-5 {
		text-align: center;
		blockquote {
			background: transparent !important;
			border-radius: 4px;
			padding: 6px 20px;
			&:before, &:after {
				display: none;
			}
			p {
				color: $color-font-default;
				font-family: $font-primary;
				font-size: 1.2em;
				font-style: normal;
			}
		}
		.testimonial-arrow-down {
			display: none;
		}
		.testimonial-author {
			padding: 6px 0 0;
			margin: 12px 0 0;
			border-top: 1px solid $color-default;
			img {
				margin: 0 auto 10px;
			}
			p {
				text-align: center;
				padding: 0;
				margin: 0;
			}
		}
	}
}

/* Style 6 */
.testimonial {
	&.testimonial-style-6 {
		text-align: center;
		blockquote {
			background: transparent !important;
			border-radius: 4px;
			border: 0;
			padding: 0 10%;
			&:before, &:after {
				display: none;
			}
			i.fa-quote-left {
				font-size: 34px;
				position: relative;
				#{$left}: -5px;
			}
			p {
				color: $color-font-default;
				font-size: 24px;
				line-height: 30px;
			}
			span {
				font-size: 16px;
				line-height: 20px;
				color: #999;
				position: relative;
				#{$left}: -5px;
			}
		}
		.testimonial-arrow-down {
			display: none;
		}
		.testimonial-author {
			padding: 0;
			margin: 6px 0 0;
			img {
				margin: 0 auto 10px;
			}
			p {
				text-align: center;
				padding: 0;
				margin: 0;
			}
		}
	}
}

/* With Quotes */
.testimonial {
	&.testimonial-with-quotes {
		blockquote {
			&:before {
				color: $color-font-default;
				display: block !important;
				#{$left}: 10px;
				top: 0;
				content: if-ltr("\201C", "\201D");
				font-size: 80px;
				font-style: normal;
				line-height: 1;
				position: absolute;
			}
			&:after {
				color: $color-font-default;
				display: block !important;
				#{$right}: 10px;
				font-size: 80px;
				font-style: normal;
				line-height: 1;
				position: absolute;
				bottom: -0.5em;
				content: if-ltr("\201D", "\201C");
			}
			p {
				padding: 0 40px;
			}
		}
	}
}

/* Transparent Background */
.testimonial {
	&.testimonial-trasnparent-background {
		blockquote {
			background: transparent !important;
		}
	}
}

/* Alternarive Font */
.testimonial {
	&.testimonial-alternarive-font {
		blockquote {
			p {
				font-family: Georgia, serif;
				font-style: italic;
				font-size: 1.2em;
			}
		}
	}
}

/* Light */
.section-text-light {
	.testimonial {
		&.testimonial {
			blockquote {
				&:before, &:after, p {
					opacity: 0.9;
					color: #FFF;
				}
			}
			.testimonial-author {
				span, strong {
					opacity: 0.6;
					color: #FFF;
				}
			}
		}
	}
}