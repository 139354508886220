/* Nav */
.nav {
	@include if-rtl() {
		padding-right: 0;
	}
	> li {
		> a:hover, > a:focus {
			background: transparent;
		}
	}
}

/* Nav List */
ul {
	&.nav-list {
		li {
			margin: 0;
			padding: 0;
			display: block;
			position: relative;
			a {
				transition: background 0.1s;
				border-bottom: 1px solid #EDEDDE;
				padding: side-values(8px 0 8px 18px);
				display: block;
				color: #666;
				font-size: 0.9em;
				text-decoration: none;
				line-height: 20px;
				&:before {
					content: "";
					display: inline-block;
					width: 0; 
					height: 0; 
					border-top: 4px solid transparent;
					border-bottom: 4px solid transparent;
					border-#{$left}: 4px solid #333;
					margin: side-values(7px 0 0 -9px);
					position: absolute;
				}
				&:hover {
					background-color: #eee;
					text-decoration: none;
				}
			}
			> a {
				&.active {
					font-weight: bold;
				}
			}
			ul {
				list-style: none;
				margin: side-values(0 0 0 25px);
				padding: 0;
			}
		}

		&.hide-not-active {
			li {
				ul {
					display: none;
				}
				&.active {
					ul {
						display: block;
					}
				}
			}
		}

		&.show-bg-active {
			.active > a,
			a.active {
				background-color: #f5f5f5;
				&:hover {
					background-color: #eee;
				}
			}
		}
	}

	&.narrow {
		li {
			a {
				padding: 4px 0;
			}
		}
	}

	&.nav-pills > li > a.active {
		color: #FFF;
		background-color: #CCC;
		&:hover, &:focus {
			color: #FFF;
			background-color: #CCC;
		}
	}
}

/* Nav Pills */
.nav-pills-center {
	text-align: center;
	> li {
		display: inline-block;
		float: none;
	}
}

.nav-pills-sm {
	> li > a {
		padding: 7px 12px;
	}
}

.nav-pills-xs {
	> li > a {
		padding: 5px 10px;
		font-size: 0.9em;
	}
}

.nav-pills {
	.nav-link {
		&.active {
			background-color: transparent;
		}
	}
}

.show {
	> .nav-pills {
		.nav-link {
			background-color: transparent;
		}	
	}
}

/* Nav Secondary */
.nav-secondary {
	background: #fafafa;
	margin: 0;
	padding: 20px 0;
	text-align: center;
	z-index: 99;
	width: 100% !important;
	.nav-pills > li {
		float: none;
		display: inline-block;
		a {
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			padding: 10px 13px;
			text-transform: uppercase;
		}
	}
	&.sticky-active {
		border-bottom-color: #e9e9e9;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	}
}

/* Boxed */
html.boxed {
	.nav-secondary {
		&.sticky-active {
			max-width: 1200px;
			left: auto !important;
		}
	}
}