/* Forms */
form label {
	font-weight: normal;
}

textarea {
	resize: vertical;
}

select {
	border: 1px solid #E5E7E9;
	border-radius: 6px;
	outline: none;
}

.label {
	font-weight: normal;
}

.form-group:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

// Form Control
.form-control {
	&:not(.form-control-lg) {
		font-size: 0.75rem;
		line-height: 1.3;
	}

	&:not(.form-control-sm):not(.form-control-lg) {
		font-size: 0.85rem;
		line-height: 1.85;
	}

	&.form-control-focused {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
		border-color: #CCC;
	}

	&:focus {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
		border-color: #CCC;
	}

	&.error {
		border-color: #a94442;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		&:focus {
			border-color: #843534;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
		}
	}
}

// IOS OVERRIDE
// -----------------------------------------------------------------------------
/* Form - iOS Override */
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	-webkit-appearance: none;
}

// PLACEHOLDERS
// -----------------------------------------------------------------------------
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	@include placeholder-color(#bdbdbd);
}

/* Form - Bootstrap Override */
.btn-lg,
.btn-group-lg > .btn {
	line-height: 1.334;
}

.input-group-btn {

	.btn {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

}

select {
	&.form-control-sm,
	&.form-control-lg {
		line-height: 1;
		height: auto !important;
	}
}

.bootstrap-timepicker-widget {
	input {
		border: 0;
	}
	table {
		td {
			input {
				width: 40px;
			}
		}
	}
}

.fileupload .btn {
    line-height: 20px;
    margin-left: -5px;
}

/* Forms Validations */
label {
	&.valid {
		display: inline-block;
		text-indent: -9999px;
	}

	&.error {
		color: #c10000;
		font-size: 0.9em;
		line-height: 18px;
		padding: 5px 0 0;
		&#captcha-error {
			margin-top: 15px;
		}
	}

}

/* Captcha */
.captcha {
	clear: both;
	overflow: hidden;
	background: #F2F2F2;
	text-align: center;
	margin-bottom: 20px;
	max-width: 160px;
	height: 78px;
	position: relative;
	input {
		border: 0;
	}
}

.captcha-input {
	position: relative;
	top: 15px;
	font-size: 14px;
}

.captcha-image {
	float: $left;
	position: relative;
	top: -5px;
	#{$left}: -13px;
}

.captcha-refresh {
	position: absolute;
	z-index: 1;
	bottom: 2px;
	right: 2px;
	border-radius: 3px;
	padding: 2px 5px;
	a, a:hover, a:focus, a:active {
		color: #333;
	}
}

/* Colorpicker */
.colorpicker-element {
	.input-group-addon {
	    padding: .375rem .75rem;
	    margin-bottom: 0;
	    font-size: 1rem;
	    font-weight: 400;
	    line-height: 1.5;
	    color: #495057;
	    text-align: center;
	    background-color: #e9ecef;
	    border: 1px solid #ced4da;
	    border-radius: 0 .25rem .25rem 0 !important;
	    border-left: 0;
	}
	.form-control {
		border-radius: .25rem 0 0 .25rem !important;
	}
}