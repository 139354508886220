/* History */
ul.history {
	margin: 0 0 25px;
	padding: 0;
	list-style: none;
	li {
		margin: 25px 0;
		padding: 0;
		clear: both;
		h4 {
			margin: 0 0 -27px 0;
			padding: 0;
		}
		p {
			margin-#{$left}: 53px;
		}
		.thumb {
			background: transparent url(../img/history-thumb.png) no-repeat 0 0;
			@include if-rtl {
				background-position: 0 -200px;
			}
			width: 227px;
			height: 160px;
			margin: 0 0 30px;
			padding: 0;
			float: $left;
			img {
				border-radius: 150px;
				margin: 7px 8px;
				width: 145px;
				height: 145px;
			}
		}
		.featured-box {
			text-align: $left;
			margin-#{$left}: 225px;
			margin-bottom: 50px;
			min-height: 115px;
			.box-content {
				border-top-color: #CCC;
			}
		}
	}
}

/* Responsive */
@media (max-width: 575px) {

	ul.history li {
		.thumb {
			display: none;
		}
		.featured-box {
			margin-#{$left}: 0;
		}
	}

}