/* Blockquotes */
blockquote {
    border-#{$left}: 5px solid #eee;
    margin: 0 0 1rem 0;
    padding: 0.5rem 1rem;
	font-size: 1em;

	&.with-borders {
		border-radius: 6px;
		border-top: 1px solid #DFDFDF;
		border-bottom: 1px solid #DFDFDF;
		border-#{$left}: 1px solid #ECECEC;
		border-#{$right}: 1px solid #ECECEC;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
		padding: 1.3rem;
	}
}

/* Small */
small, .small {
	font-size: 70%;
}

/* Paragraphs */
p {
	color: $color-font-default;
	line-height: 24px;
	margin: 0 0 20px;
	&.featured {
		font-size: 1.6em;
		line-height: 1.5em;
	}
	&.lead {
		line-height: 1.8rem;
	}
	.alternative-font {
		display: inline-block;
		margin-top: -15px;
		position: relative;
		top: 3px;
		margin-bottom: -6px;
	}
}

/* Links */
a, a:hover, a:focus {
	color: #CCC;
}

/* Drop Caps */
p.drop-caps {
	&:first-letter {
		float: $left;
		font-size: 75px;
		line-height: 60px;
		padding: 4px;
		margin-#{$right}: 5px;
		margin-top: 5px;
		font-family: Georgia;
	}

	&.drop-caps-style-2:first-letter {
		background-color: #CCC;
		color: #FFF;
		padding: 6px;
		margin-#{$right}: 5px;
		border-radius: 4px;
	}
}

/* Default Font Style */
.default-font {
	font-family: $font-primary;
}

/* Alternative Font Style */
.alternative-font {
	color: #CCC;
	font-family: $font-secondary;
	font-size: 1.6em;
}