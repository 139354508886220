/* Video */
section.video {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding: 50px 0;
	position: relative;
	overflow: hidden;
	width: 100%;
	.container {
		position: relative;
		z-index: 3;
	}
	.video-overlay {
		background: transparent url(../img/video-overlay.png) repeat 0 0;
		position: absolute;
		top: 0;
		#{$left}: 0;
		#{$right}: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	&.video-overlay-dark {
		.video-overlay {
			background-image: url(../img/video-overlay-dark.png);
		}
	}
}

html.boxed {
	section.video {
		> div:first-child {
			z-index: 0 !important;
			video {
				z-index: 0 !important;
			}
		}
	}
}